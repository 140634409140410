import { ref, watch, onUnmounted, getCurrentInstance } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { isEnableBed, isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import prepaidStoreModule from '../prepaidStoreModule';

export default function usePrepaidList() {
  const STORE_MODULE_NAME = 'prepaid';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, prepaidStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    id: 0,
    isIncome: true,
    type: null,
    name: '',
    issueDate: moment(new Date()).toDate(),
    amount: null,
    cashbook: null,
    apartment: null,
    room: null,
    bed: null,
    tenant: null,
    reservation: null,
    payer: '',
    receiver: '',
    note: '',
    allocation: false,
    allocationStartDate: null,
    allocationEndDate: null,
    items: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const isVMarket = process.env.VUE_APP_PROVIDER === 'vmarket';
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Hợp đồng'),
      field: 'contract.name',
    },
    {
      label: t('Tòa nhà'),
      field: 'contract.apartment.name',
    },
    {
      label: t('Phòng'),
      field: 'contract.room.name',
    },
    {
      label: t('Giường'),
      field: 'contract.bed.name',
      hidden: isVMarket,
    },
    {
      label: t('Số tiền thừa'),
      field: 'remainPrepaids',
      type: 'number',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  const remain = ref(0);
  // filter
  const cashbook = ref(null);
  const apartment = ref(null);
  const location = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref(null);
  const type = ref(null);
  const isIncome = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchIncomeExpenses = () => {
    isLoading.value = false;
    store
      .dispatch('prepaid/fetchPrepaids', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
        remain.value = response.data.remain;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const vm = getCurrentInstance().proxy
  const exportData = () => {
    store
      .dispatch('prepaid/exportPrepaids', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchIncomeExpenses();
  };

  const deleteIncomeExpenses = incomeExpenses => {
    store
      .dispatch('prepaid/deletePrepaid', {
        ids: incomeExpenses.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch
  watch(cashbook, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.cashbookId = val.id;
    } else {
      delete filter.cashbookId;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });
  watch(startDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.startDate = val;
    } else {
      delete filter.startDate;
    }
    updateParams({ filter });
  });
  watch(endDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.endDate = val;
    } else {
      delete filter.endDate;
    }
    updateParams({ filter });
  });
  watch(isIncome, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.isIncome = val.value;
    } else {
      delete filter.isIncome;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  return {
    analytic,
    item,
    remain,
    columns,
    rows,
    cashbook,
    apartment,
    room,
    bed,
    status,
    type,
    isIncome,
    startDate,
    endDate,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    fetchIncomeExpenses,
    deleteIncomeExpenses,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    exportData,
    resolveColWidthIfDisableBed,
    t,
  };
}
